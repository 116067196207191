import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessLoanTermsSelectionComponent, LoanPurposeSelectionComponent } from '@portal-workspace/grow-ui-library';
import {
  BrokerageSelectionType,
  BrokerageSelectionValue,
  BusinessSearchResultValue,
  BusinessSearchValue,
  LoanPurposeValue,
  LoanTermType,
  PaymentFrequencyType,
  PaymentFrequencyValue,
  Quote,
  SearchCompanyByABNResult, SliderComponentValue, YesNoValue, calculateBusinessLoanEstimation,
} from '@portal-workspace/grow-shared-library';
import { Subscription } from 'rxjs';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import {
  doMarkAll,
  markTriggerSubject
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { Moment } from 'moment';
import { TotalPaymentBreakupDialogData } from '@portal-workspace/grow-shared-library';
import numeral from 'numeral';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../service/application.service';
import { RatecardDetails, } from '@portal-workspace/grow-shared-library';
import { LoanTermValue } from '@portal-workspace/grow-shared-library';
import { AppCalculator } from '@portal-workspace/grow-shared-library';
import { navigationUrlForApplications } from '../../service/navigation-urls';
import { MatButtonModule } from '@angular/material/button';
import { BrokerageSelectionComponent } from '@portal-workspace/grow-ui-library';

import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './business-loans-calculator.page.html',
  styleUrls: ['business-loans-calculator.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MarkDirective,
    MatButtonModule,
    YesNoComponent,
    BrokerageSelectionComponent,
    PaymentFrequencySelectionComponent,
    BusinessLoanTermsSelectionComponent,
    CurrencyInputComponent,
    LoanPurposeSelectionComponent
],
})

export class BusinessLoansCalculatorPage implements OnInit {

  maxLimit: number | null = null;

  subscriptions: Subscription[] = [];

  markTriggerSubject = markTriggerSubject;

  formGroupCalculator!: FormGroup<{
    loanPurpose: FormControl<LoanPurposeValue>,
    loanTerms: FormControl<LoanTermValue>,
    loanAmount: FormControl<SliderComponentValue>,
    brokerage: FormControl<BrokerageSelectionValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScore: FormControl<YesNoValue>,
    directorScore: FormControl<YesNoValue>,
    previousLoan: FormControl<YesNoValue>,
    abnGstAgeAboveThreshold: FormControl<YesNoValue>,
  }>;

  formControlLoanPurpose!: FormControl<LoanPurposeValue>;
  formControlLoanAmount!: FormControl<SliderComponentValue>;
  formControlLoanTerms!: FormControl<LoanTermValue>;
  formControlBrokerage!: FormControl<BrokerageSelectionValue>;
  formControlPaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlPropertyOwner!: FormControl<YesNoValue>;
  formControlAdverseOnFile!: FormControl<YesNoValue>;
  formControlEquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlDirectorScore!: FormControl<YesNoValue>;
  formControlPreviousLoan!: FormControl<YesNoValue>;
  formControlAbnGstAgeAboveThreshold!: FormControl<YesNoValue>;

  rateCard!: RatecardDetails;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
    private activatedRoute: ActivatedRoute,
    private appService: ApplicationService,
    private router: Router) {

    this.initCalculator();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.rateCard = (this.activatedRoute.snapshot.data as any).ratecard;

    // update maxLimit limit
    this.subscriptions.push(this.formControlPropertyOwner.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
      })
    ).subscribe());

    this.subscriptions.push(this.formControlEquifaxScoreAboveThreshold.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
        if(r){
          this.formControlDirectorScore.setValue(false)
        }else{
          this.formControlDirectorScore.setValue(true)
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlDirectorScore.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
      })
    ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlPropertyOwner.valueChanges.pipe(
      tap(r => {
        this.updatePreviousLoanValidation();
      })
    ).subscribe())

  }

  updatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlPropertyOwner.value;
    if (propertyOwner) {
      this.formControlPreviousLoan.clearValidators();
    } else {
      this.formControlPreviousLoan.setValidators([Validators.required]);
    }
    this.formControlPreviousLoan.updateValueAndValidity();
  }

  private updateMaxLimit() {
    const isPropertyOwner = this.formControlPropertyOwner.value;
    const scoreAbove600 = this.formControlEquifaxScoreAboveThreshold.value;
    const scoreBetween500And600 = this.formControlDirectorScore.value;
    if (isPropertyOwner && scoreAbove600) {
      this.maxLimit = 500000;
    } else if (!isPropertyOwner && scoreAbove600) {
      this.maxLimit = 100000;
    } else if (isPropertyOwner && !scoreAbove600 && scoreBetween500And600) {
      this.maxLimit = 250000;
    } else if (!isPropertyOwner && !scoreAbove600 && scoreBetween500And600) {
      this.maxLimit = 50000;
    }
  }

  initCalculator() {
    this.formControlLoanPurpose = this.formBuilder.control(null, [Validators.required]);
    this.formControlLoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlLoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlBrokerage = this.formBuilder.control(null, [Validators.required]);
    this.formControlPaymentFrequency = this.formBuilder.control({ type: 'Weekly', name: 'Weekly' }, [Validators.required]);
    this.formControlPropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlAdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlEquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlDirectorScore = this.formBuilder.control(true, [Validators.required]);
    this.formControlPreviousLoan = this.formBuilder.control(null);
    this.formControlAbnGstAgeAboveThreshold = this.formBuilder.control(true, [Validators.required]);

    this.formGroupCalculator = this.formBuilder.group({
      loanPurpose: this.formControlLoanPurpose,
      loanTerms: this.formControlLoanTerms,
      loanAmount: this.formControlLoanAmount,
      brokerage: this.formControlBrokerage,
      paymentFrequency: this.formControlPaymentFrequency,
      propertyOwner: this.formControlPropertyOwner,
      adverseOnFile: this.formControlAdverseOnFile,
      equifaxScore: this.formControlEquifaxScoreAboveThreshold,
      directorScore: this.formControlDirectorScore,
      previousLoan: this.formControlPreviousLoan,
      abnGstAgeAboveThreshold: this.formControlAbnGstAgeAboveThreshold,
    });
  }

  onCalculateEstimation(event: Event) {
    markTriggerSubject(this.formGroupCalculator).next(true);
    doMarkAll(this.formGroupCalculator);
    if (this.formGroupCalculator.invalid) {
      this.applicationDialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields across tabs are filled in.',
      });
    } else {
      this.subscriptions.push(this.applicationDialogService.openAssetQuotationDialog({
        loadFn: (date) => {
          return this.calculateEstimation(date);
        },
        isQuote: true,
        paymentChartHideBalloonPayment: false,
        paymentChartHideBrokerOriginationFee: true,
        paymentChartHideBrokerage: false,
        showPaymentChart: true,
        showArmotizationChart: true,
        showRepaymentEstimation: true,
        showArmotisationSchedule: true,
      }).afterClosed()
        .pipe(
          tap(r => {
            if (r && r.acceptedQuotation) {
              const quoteData = this.saveQuoteData();
              this.appService.saveQuote(quoteData).subscribe(async res => {
                if (res.status) {
                  // const quoteId = res.payload.quoteId;
                  // this.toastService.quickInfoToast(`Quote saved (Id: ${quoteId})`);
                  await this.router.navigate(navigationUrlForApplications())
                } else {
                  this.applicationDialogService.openAlertDialog({
                    message: `Error`,
                    // subMessage: " Couldn\'t save quote. Try again later."
                    subMessage: "Something is wrong. Try again."
                  });
                }
              })
            }
          })
        ).subscribe());
    }
  }

  private saveQuoteData(): Quote {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const loanCalValues = this.formGroupCalculator.getRawValue();
    const brokerageValue: BrokerageSelectionValue = this.formControlBrokerage.value;
    const loanAmount = this.formControlLoanAmount.value!;
    const quoteValue: Quote = {
      LoanAmount: (loanCalValues.loanAmount ?? '0').toString(),
      RepaymentFrequency: loanCalValues.paymentFrequency?.type!,
      LoanTerm: parseInt(loanCalValues.loanTerms?.type ?? '0'),
      BrokeragePercentage: parseFloat(loanCalValues.brokerage?.type ?? '0'),
      BrokerageAmount: new AppCalculator().calculateBrokerageAmount(this.formControlLoanAmount.value ?? 0, Number(brokerageValue?.type ?? '0')),
      QuoteAsJson: encodeURI(JSON.stringify(loanCalValues)),
    };
    return quoteValue
  }

  private calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlPaymentFrequency.value;
    const paymentFrequencyType: PaymentFrequencyType = paymentFrequencyValue?.type ?? 'Monthly';
    const loanTermValue: LoanTermValue = this.formControlLoanTerms.value;
    const loanTermType: LoanTermType | null = loanTermValue?.type ?? null;
    const brokerageValue: BrokerageSelectionValue = this.formControlBrokerage.value;
    const brokerageType: BrokerageSelectionType = brokerageValue?.type ?? '0';
    const brokerage = Number(brokerageValue?.type ?? 0);
    const loanAmount = numeral(this.formControlLoanAmount.value).value() ?? 0;
    const businessSearchValue: BusinessSearchValue = this.createBusinessSearchValue();
    const bureauReport = businessSearchValue?.result!;
    const lowEquifaxScore = this.formControlEquifaxScoreAboveThreshold.value ?? false;
    const adverseOnFile = this.formControlAdverseOnFile.value ?? false;
    const propertyOwner = this.formControlPropertyOwner.value ?? false;
    const directorScore = this.formControlDirectorScore.value ?? false;

    const r = calculateBusinessLoanEstimation(date, {
      type: 'BusinessLoans',
      paymentFrequencyType,
      loanTermType,
      brokerageType,
      loanAmount,
      businessSearchValue,
      propertyOwner,
      brokerage,
      adverseOnFile,
      lowEquifaxScore,
      directorScore,
      rateCard: this.rateCard,
      bureauReport,
    });

    return r.totalPaymentBreakupDialogData;
  }

  createBusinessSearchValue(): BusinessSearchResultValue {
    if (this.formControlAbnGstAgeAboveThreshold.value) {
      return {
        type: 'search-result',
        result: {
          ABNAgeMonths: 999,
          GSTAgeMonths: 999
        } as SearchCompanyByABNResult
      } as BusinessSearchResultValue;
    } else {
      return {
        type: 'search-result',
        result: {
          ABNAgeMonths: 1,
          GSTAgeMonths: 1
        } as SearchCompanyByABNResult
      } as BusinessSearchResultValue;
    }
  }
}
