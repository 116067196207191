<div class="overdraft-product-page container-fluid">
  @if (loader.DataLoading.inProgress$ | async) {
    <custom-content-loader></custom-content-loader>
  }
  <div [ngClass]="{hidden: (loader.DataLoading.inProgress$ | async)}">
    <div class="row text-white">
      <div class="col-md-4 mb-2">
          <mat-card class="mat-card-background-dark">
              <mat-card-content>
                  <div class="row-one-label">
                      Total balance
                  </div>
                  <div class="overdraft-values mt-2">
                      ${{totalBalance}} M
                  </div>
              </mat-card-content>
              <mat-card-content class="mt-4">
                  <div class="row-one-label">
                      Total Limit
                  </div>
                  <div class="overdraft-values mt-2">
                      ${{totalLimit}} M
                  </div>
              </mat-card-content>
          </mat-card>
      </div>
      <div class="col-md-4 mb-2">
          <mat-card class="mat-card-background-blue">
              <mat-card-content>
                  <div class="row-one-label">
                      Active accounts
                  </div>
                  <div class="overdraft-values mt-2">
                      {{totalActiveAccounts | number}}
                  </div>
              </mat-card-content>
              <mat-card-content class="mt-4">
                  <div class="row-one-label">
                      Active cards
                  </div>
                  <div class="overdraft-values mt-2">
                      {{totalActiveCards}}
                  </div>
              </mat-card-content>
          </mat-card>
      </div>
      <div class="col-md-4 mb-2">
          <mat-card class="mat-card-background-teal">
              <mat-card-content>
                  <div class="row-one-label">
                      Balance in arrears
                  </div>
                  <div class="overdraft-values mt-2">
                    <span [ngClass]="balanceArrearsSum.toString().length === 4 ? 'font-size-large' : balanceArrearsSum.toString().length === 5 ? 'font-size-medium' : 'font-size-small'">
                      ${{ balanceArrearsSum }} K
                    </span>
                    (
                    <span [ngClass]="totalBalanceArrears.toString().length === 4 ? 'font-size-large' : totalBalanceArrears.toString().length === 5 ? 'font-size-medium' : 'font-size-small'">
                      {{ totalBalanceArrears }} K
                    </span>
                    )
                  </div>
              </mat-card-content>
              <mat-card-content class="mt-4">
                  <div class="row-one-label">
                      Accounts in arrears
                  </div>
                  <div class="overdraft-values mt-2">
                      {{numberAccountsArrears | number}}
                  </div>
              </mat-card-content>
          </mat-card>
      </div>
  </div>
  
  <div class="row">
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
          <mat-card>
              <mat-card-content>
                  <tag-box state="Last 30 days"></tag-box>
                  <div class="overdraft-label mt-2">
                      Total transaction volume
                  </div>
                  <div class="overdraft-values mt-3">
                      ${{totalLast30DaysVolume}} M
                  </div>
                  <div class="mt-3">
                      @if(last30DaysVolumePercentageChange > 0){
                      <h3 class="text-success rolling-data mr-2">
                          <div class="icon icon-shape green-lighten">
                              <i class="mdi mdi-arrow-up"></i>
                          </div>
                          {{last30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      } @else if(last30DaysVolumePercentageChange == 0){
                      <h3 class="text-success rolling-data mr-2">
                          {{last30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      }
                      @else{
                      <h3 class="text-danger rolling-data mr-2">
                          <div class="icon icon-shape red-lighten">
                              <i class="mdi mdi-arrow-down"></i>
                          </div>
                          {{last30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      }
                  </div>
              </mat-card-content>
              <mat-card-content>
                  <div fxLayoutAlign="space-between center">
                      <div class="col-6">
                          <div class="chart-wrapper">
                              <div class="canvas-wrapper">
                                  <canvas #last30daysTotalTransactionsVolumeChart></canvas>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 mt-4">
                          <div class="padding-left first-shadow">
                              <span class="overdraft-label">Bank and BPay transactions</span>
                              <div class="mt-1 overdraft-value-large">
                                  ${{get30DayBankTransactionVolume}} M
                              </div>
                          </div>
                          <div class="padding-left second-shadow mt-2">
                              <span class="overdraft-label">Card transactions</span>
                              <div class="mt-1 overdraft-value-large">
                                  ${{get30DaysCardTransactionVolume}} M
                              </div>
                          </div>
                      </div>
                  </div>
              </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
          <mat-card>
              <mat-card-content>
                  <tag-box state="Prior 30 days"></tag-box>
                  <div class="overdraft-label mt-2">
                      Total transaction volume
                  </div>
                  <div class="overdraft-values mt-3">
                      ${{totalPrior30DaysVolume}} M
                  </div>
                  <div class="mt-3">
                      @if(prior30DaysVolumePercentageChange > 0){
                      <h3 class="text-success rolling-data mr-2">
                          <div class="icon icon-shape green-lighten">
                              <i class="mdi mdi-arrow-up"></i>
                          </div>
                          {{prior30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      } @else if(prior30DaysVolumePercentageChange == 0){
                      <h3 class="text-success rolling-data mr-2">
                          {{prior30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      }
                      @else{
                      <h3 class="text-danger rolling-data mr-2">
                          <div class="icon icon-shape red-lighten">
                              <i class="mdi mdi-arrow-down"></i>
                          </div>
                          {{prior30DaysVolumePercentageAbsolute | percentage}}
                      </h3>
                      }
                  </div>
              </mat-card-content>
              <mat-card-content>
                  <div fxLayoutAlign="space-between center">
                      <div class="col-6">
                          <div class="chart-wrapper">
                              <div class="canvas-wrapper">
                                  <canvas #prior30daysTotalTransactionsVolumeChart></canvas>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 mt-4">
                          <div class="padding-left first-shadow">
                              <span class="overdraft-label">Bank and BPay transactions</span>
                              <div class="mt-1 overdraft-value-large">
                                  ${{get60DayBankTransactionVolume}} M
                              </div>
                          </div>
                          <div class="padding-left second-shadow mt-2">
                              <span class="overdraft-label">Card transactions</span>
                              <div class="mt-1 overdraft-value-large">
                                  ${{get60DaysCardTransactionVolume}} M
                              </div>
                          </div>
                      </div>
                  </div>
              </mat-card-content>
          </mat-card>
        </div>
      </div>
  </div>

    <div class="row">
        <div class="col-md-6 mb-2 mt-4">
          <div class="card card-stats">
          <mat-card>
            <mat-card-content>
              <div class="overdraft-label">
                Total spent last 30 days
              </div>
              <div class="overdraft-values mt-3">
                ${{totalSpendLast30Days}} M
              </div>
              <div class="mt-3">
                @if(last30DaysSpendPercentageChange > 0){
                  <h3 class="text-success rolling-data mr-2">
                    <div class="icon icon-shape green-lighten">
                      <i class="mdi mdi-arrow-up"> </i>
                  </div>
                  {{last30DaysSpendPercentageAbsolute | percentage}}
                </h3>
                } @else if(last30DaysSpendPercentageChange == 0){
                  <h3 class="text-success rolling-data mr-2">
                    {{last30DaysSpendPercentageAbsolute | percentage}}
                  </h3>
                }
                @else{
                  <h3 class="text-danger rolling-data mr-2">
                    <div class="icon icon-shape red-lighten">
                      <i class="mdi mdi-arrow-down"> </i>
                    </div>
                    {{last30DaysSpendPercentageAbsolute | percentage}}
                  </h3>
                }
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        </div>
        <div class="col-md-6 mb-2 mt-4">
          <div class="card card-stats">
          <mat-card>
            <mat-card-content>
              <div class="overdraft-label">
                Total spent prior last 30 days
              </div>
              <div class="overdraft-values mt-3">
                ${{totalSpendPrior30Days}} M
              </div>
              <div class="mt-3">
                @if(prior30DaysSpendPercentageChange > 0){
                  <h3 class="text-success rolling-data mr-2">
                    <div class="icon icon-shape green-lighten">
                      <i class="mdi mdi-arrow-up"> </i>
                  </div>
                  {{prior30DaysSpendPercentageAbsolute | percentage}}
                </h3>
                } @else if(prior30DaysSpendPercentageChange == 0){
                  <h3 class="text-success rolling-data mr-2">
                    {{prior30DaysSpendPercentageAbsolute | percentage}}
                  </h3>
                }
                @else{
                  <h3 class="text-danger rolling-data mr-2">
                    <div class="icon icon-shape red-lighten">
                      <i class="mdi mdi-arrow-down"> </i>
                    </div>
                    {{prior30DaysSpendPercentageAbsolute | percentage}}
                  </h3>
                }
              </div>
            </mat-card-content>
          </mat-card>
          </div>
        </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="New Accounts"></tag-box>
            <div fxLayout="row">
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Last 30 days
                </div>
                <div class="overdraft-values mt-3">
                  {{last30DaysNewAccountCount}}
                </div>
              </div>
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Prior 30 days
                </div>
                <div class="overdraft-values mt-3">
                  {{prior30DaysNewAccountCount}}
                </div>
              </div>
            </div>
            <div class="mt-3 ml-3">
              @if(last30DaysNewAccountPercentageChange > 0){
                <h3 class="text-success rolling-data mr-2">
                  <div class="icon icon-shape green-lighten">
                    <i class="mdi mdi-arrow-up"> </i>
                </div>
                {{last30DaysNewAccountPercentageAbsolute | percentage}}
              </h3>
              } @else if(last30DaysNewAccountPercentageChange == 0){
                <h3 class="text-success rolling-data mr-2">
                  {{last30DaysNewAccountPercentageAbsolute | percentage}}
                </h3>
              }
              @else{
                <h3 class="text-danger rolling-data mr-2">
                  <div class="icon icon-shape red-lighten">
                    <i class="mdi mdi-arrow-down"> </i>
                  </div>
                  {{last30DaysNewAccountPercentageAbsolute | percentage}}
                </h3>
              }
            </div>
          </mat-card-content>
        </mat-card>
        </div>
      </div>
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="Total Accounts"></tag-box>
            <div fxLayout="row">
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  This month
                </div>
                <div class="overdraft-values mt-3">
                  {{currentMonthNewAccountCount | number}}
                </div>
              </div>
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Last month
                </div>
                <div class="overdraft-values mt-3">
                  {{lastMonthNewAccountCount | number}}
                </div>
              </div>
            </div>
            <div class="mt-3 ml-3">
              @if(last30DaysTotalAccountPercentageChange > 0){
                <h3 class="text-success rolling-data mr-2">
                  <div class="icon icon-shape green-lighten">
                    <i class="mdi mdi-arrow-up"> </i>
                  </div>
                  {{last30DaysTotalAccountPercentageAbsolute | percentage}}
                </h3>
              } @else if(last30DaysTotalAccountPercentageChange == 0){
                <h3 class="text-success rolling-data mr-2">
                  {{last30DaysTotalAccountPercentageAbsolute | percentage}}
                </h3>
              }
              @else{
                <h3 class="text-danger rolling-data mr-2">
                  <div class="icon icon-shape red-lighten">
                    <i class="mdi mdi-arrow-down"> </i>
                  </div>
                  {{last30DaysTotalAccountPercentageAbsolute | percentage}}
                </h3>
              }
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="New Cards"></tag-box>
            <div fxLayout="row">
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Last 30 days
                </div>
                <div class="overdraft-values mt-3">
                  {{last30DaysCardCountResult}}
                </div>
              </div>
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Prior 30 days
                </div>
                <div class="overdraft-values mt-3">
                  {{prior30DaysCardCountResult}}
                </div>
              </div>
            </div>
            <div class="mt-3 ml-3">
              @if(last30DaysCardCountPercentageChange > 0){
                <h3 class="text-success rolling-data mr-2">
                  <div class="icon icon-shape green-lighten">
                    <i class="mdi mdi-arrow-up"> </i>
                  </div>
                  {{last30DaysCardCountPercentageAbsolute | percentage}}
                </h3>
              } @else if(last30DaysCardCountPercentageChange == 0){
                <h3 class="text-success rolling-data mr-2">
                  {{last30DaysCardCountPercentageAbsolute | percentage}}
                </h3>
              }
              @else{
                <h3 class="text-danger rolling-data mr-2">
                  <div class="icon icon-shape red-lighten">
                    <i class="mdi mdi-arrow-down"> </i>
                  </div>
                  {{last30DaysCardCountPercentageAbsolute | percentage}}
                </h3>
              }
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      </div>
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="Total Cards"></tag-box>
            <div fxLayout="row">
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  This month
                </div>
                <div class="overdraft-values mt-3">
                  {{currentMonthCardCountResult | number}}
                </div>
              </div>
              <div class="col-6">
                <div class="overdraft-label mt-2">
                  Last month
                </div>
                <div class="overdraft-values mt-3">
                  {{lastMonthCardCountResult | number}}
                </div>
              </div>
            </div>
            <div class="mt-3 ml-3">
              @if(last30DaysTotalCardPercentageChange > 0){
                <h3 class="text-success rolling-data mr-2">
                  <div class="icon icon-shape green-lighten">
                    <i class="mdi mdi-arrow-up"> </i>
                  </div>
                  {{last30DaysTotalCardPercentageAbsolute | percentage}}
                </h3>
              } @else if(last30DaysTotalCardPercentageChange == 0){
                <h3 class="text-success rolling-data mr-2">
                  {{last30DaysTotalCardPercentageAbsolute | percentage}}
                </h3>
              }
              @else{
                <h3 class="text-danger rolling-data mr-2">
                  <div class="icon icon-shape red-lighten">
                    <i class="mdi mdi-arrow-down"> </i>
                  </div>
                  {{last30DaysTotalCardPercentageAbsolute | percentage}}
                </h3>
              }
            </div>
          </mat-card-content>
        </mat-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="New Accounts"></tag-box>
              <div class="overdraft-label mt-2">
                Last 12 months
              </div>
          </mat-card-content>
          <mat-card-content>
            <div gdArea="chart" class="chart-wrapper mt-2">
              <div class="canvas-wrapper">
                <canvas class="chart-canvas" id="account-chart" height="228"> </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        </div>
      </div>
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="Spend"></tag-box>
              <div class="overdraft-label mt-2">
                Last 12 months
              </div>
          </mat-card-content>
          <mat-card-content>
            <div gdArea="chart" class="chart-wrapper mt-2">
              <div class="canvas-wrapper">
                <canvas class="chart-canvas" id="spend-chart" height="228"> </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2 mt-4">
        <div class="card card-stats">
        <mat-card>
          <mat-card-content>
              <tag-box state="Limit"></tag-box>
              <div class="overdraft-label mt-2">
                Last 12 months
              </div>
          </mat-card-content>
          <mat-card-content>
            <div gdArea="chart" class="chart-wrapper mt-2">
              <div class="canvas-wrapper">
                <canvas class="chart-canvas" id="limit-chart" height="228"> </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      </div>
    </div>
  </div>
</div>