import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {openWindowWithLink, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { navigationUrlForAssetFinance } from '../../service/navigation-urls';
import { environment } from 'apps/portal2/src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import {AssetFinanceApplication, AssetFinanceProductType} from '@portal-workspace/grow-shared-library';

// NOTE: Page to land when you are in DRAFT (new unsubmitted) asset application
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './asset-product-selector.page.html',
    styleUrls: ['./asset-product-selector.page.scss'],
    standalone: true,
    imports: [MatCardModule,MatDividerModule,CommonModule,MatSlideToggleModule,MatButtonModule],
})
export class AssetProductSelectorPage implements OnInit {
  selectedProductType: AssetFinanceProductType = 'FAST DOC';
  isSwitchToEasyDoc: boolean = true; // Default to true
  isSwitchLocked: boolean = false; // Tracks if the user has manually toggled the switch
  existingApplication: AssetFinanceApplication | null = null; // Editing an existing application
  lastSelectedProductType: AssetFinanceProductType | null = null; // Track last selected product
  
  constructor(private router: Router, private route: ActivatedRoute) {}
  
  async ngOnInit() {
    setupUntilDestroy(this);
    this.existingApplication = (this.route.snapshot.data as any).application;
    this.populate();
  }
  
  populate() {
    if (this.existingApplication && this.existingApplication.AppInfo.productType) {
      this.isSwitchToEasyDoc = this.existingApplication.AppInfo.isSwitchToEasyDoc;
      this.selectedProductType = this.existingApplication.AppInfo.productType;
    } else {
      this.selectedProductType = 'FAST DOC';
      this.isSwitchToEasyDoc = true; // Default to true if no existing application
    }
    this.lastSelectedProductType = this.selectedProductType; // Set initial product type
  }
  
  selectProductType(productType: AssetFinanceProductType): void {
    if (this.lastSelectedProductType !== productType) {
      // Reset the switch to true if a new product is selected
      this.isSwitchToEasyDoc = productType === 'FAST DOC';
      this.isSwitchLocked = false; // Allow auto-toggle for new selection
    }
  
    this.selectedProductType = productType;
    this.lastSelectedProductType = productType;
  
    if (!this.isSwitchLocked) { // Only auto-toggle if not manually locked
      if (productType === 'FAST DOC') {
        this.isSwitchToEasyDoc = true; // Automatically enable switch for FAST DOC
      } else {
        this.isSwitchToEasyDoc = false; // Disable switch for other product types
      }
    }
  }
  
  isProductTypeSelected(productType: AssetFinanceProductType): boolean {
    return this.selectedProductType === productType;
  }
  
  onSwitchToEasyDoc(switchToEasyDoc: boolean) {
    this.isSwitchToEasyDoc = switchToEasyDoc;
    this.isSwitchLocked = true; // Lock the switch state after manual change
  }

  async onNext() {
    if(this.existingApplication){
      const urlTree = navigationUrlForAssetFinance(this.router, this.selectedProductType, this.isSwitchToEasyDoc, this.existingApplication.ApplicationId);
      await this.router.navigateByUrl(urlTree);
    }else{
      const urlTree = navigationUrlForAssetFinance(this.router, this.selectedProductType, this.isSwitchToEasyDoc, undefined);
      await this.router.navigateByUrl(urlTree);
    }
  }

  downloadFile(){
    openWindowWithLink(environment.assetProductGuideUrl);
  }
}

